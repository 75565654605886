/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Locations from '../Location/Locations';
import Stats from '../Stats/Stats';
import LastPayload from '../Stats/LastPayload';

async function clientData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/app/client/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function Client(props) {
  const [client, setClient] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await clientData(props.token, id);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setClient(data.client);
        setFeatured(data.featured);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, id])



  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Client error!</p>
        </div>
      : null}
      {client ? 
        <div>
          <a href={'/company/' + client.company_id + '/'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> OMS</button>
          </a>

          {props.role === 1 || props.role === 2 ?
            <div className="uk-width-1-1@s uk-width-1-4@m uk-child-width-1-1@s uk-child-width-1-2@m uk-margin-auto uk-align-right" uk-grid="">
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-warning" href={'../edit/' + client.id}>EDIT</a>
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-danger" href={'../delete/' + client.id}>DELETE</a>
            </div>
          : null}

          <h2>Client: {client.name}</h2>
          <div className="uk-card uk-card-default">
            <div className="uk-card-header">
                <h3 className="uk-card-title">ID: {client.id}</h3>
            </div>
            <div className="uk-card-body">
              <p>Clinic ID: {client.clinic_id}</p>
              <p>OMS: {client.company_name}</p>
            </div>
          </div>
          <hr></hr>
          <div className={"uk-grid-match uk-child-width-1-1@s  uk-child-width-1-" + (featured ? '4@m' : '2@m')} uk-grid="">
            <div><Stats token={props.token} id={id} type={'client'} period={'Daily'}/></div>
            <hr className="uk-hidden@m"></hr>
            <div><Stats token={props.token} id={id} type={'client'} period={'Weekly'}/></div>
            <hr className="uk-hidden@m"></hr>
            <div><Stats token={props.token} id={id} type={'client'} period={'Monthly'}/></div>
            <hr className="uk-hidden@m"></hr>
            <div><Stats token={props.token} id={id} type={'client'} period={'Total'}/></div>
            <hr className="uk-hidden@m"></hr>
            {featured ? <div><LastPayload featured={featured}/></div> : null}
          </div>
          <hr></hr>
          <Locations client={id} token={props.token} role={props.role}/>
        </div>
      : null}
    </div>
  );
}
