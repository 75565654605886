/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useParams } from "react-router-dom";

async function sendData(token, data) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/clients',
    {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function ClientAdd(props) {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    'clinic_id': '',
    'company_id': id,
    'name': '',
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);

  const handleInputChange = async e => {
    console.log(e.target);
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    const response = await sendData(props.token, formData);
    if (!response) {
        setError(true);
        return
    }
    if (response.error) {
      setError(true);
      setResponse(response);
      console.log(response);
      return
    }
    setError(false);
    setSuccess(true);
    setResponse(response);
    console.log(response);
  }

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Client error!</p>
        </div>
      : null}
      {success ?
        <div className="uk-alert-success" uk-alert="">
            <a className="uk-alert-close" uk-close=""></a>
            <p>Client added successfully</p>
            <a href={'./' + response.client.id + '/'}>See client</a>
        </div>
      : null}
        <a href={'../'}>
          <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> OMS</button>
        </a>
        <div className="uk-card uk-card-default uk-margin-top">
          <div className="uk-card-header">
              <h3 className="uk-card-title">New Client</h3>
          </div>
          <div className="uk-card-body">
          <form className="uk-form-horizontal" uk-grid="" onSubmit={handleSubmit}>

            <div className="uk-width-1-1@s uk-width-1-expand@m">
              <label className="uk-form-label" htmlFor="company_id">OMS</label>
              <div className="uk-form-controls">
                <select className="uk-select" id="company_id" value={formData['company_id']} onChange={handleInputChange}>
                  <option value="">Please select...</option>
                  <option value="3">Sycle</option>
                  <option value="2">CounselEar</option>
                  <option value="1">Blueprint</option>
                </select>
              </div>
            </div>
            
            <div className="uk-width-1-1@s uk-width-1-expand@m">
              <label className="uk-form-label" htmlFor="clinic_id">Clinic ID</label>
              <div className="uk-form-controls">
                  <input className="uk-input" id="clinic_id" type="text" placeholder="Clinic ID" value={formData['clinic_id']} onChange={handleInputChange}/>
              </div>
            </div>

            <div className="uk-width-1-1@s uk-width-1-expand@m">
              <label className="uk-form-label" htmlFor="name">Name</label>
              <div className="uk-form-controls">
                  <input className="uk-input" id="name" type="text" placeholder="Name" value={formData['name']} onChange={handleInputChange}/>
              </div>
            </div>

            <div className="uk-width-1-1@s uk-width-1-1@m">
              <button className="uk-button uk-button-default" type="submit">Submit</button>
            </div>
          </form>
          </div>
        </div>
    </div>
  );
}
